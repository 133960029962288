import { dropdownToggle } from '../../utils/utils';
import './search.scss';

const Search = () => {
    const $searchForm = document.querySelector('.search__form');
    const $searchInput = $searchForm.querySelector('.search__input');
    const $searchButtons = document.querySelectorAll('.js-search-button');

    $searchButtons.forEach((button) => {
        const $dropdownBlock = button.closest('.js-search-product-info').querySelector('.js-search-dropdown');
        button.addEventListener('click', () => {
            dropdownToggle($dropdownBlock);
            button.classList.toggle('active');
        });
    });

    $searchForm.addEventListener('submit', function (e) {
        e.preventDefault();

        if ($searchInput.value) {
            location.href = `/search/${$searchInput.value}`;
        }
    });
};

Search();
